.wrapper {
  padding: 80px 160px 0;
}
.title {
  margin-left: 90px;
  font-size: 20px;
  margin-bottom: 48px;
}

.formWrapper {
  margin-left: 90px;
  width: 440px;
  margin-top: 48px;
}
