.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  .iconWrapper {
    width: 100%;
    height: 40%;
    // background: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
      color: #fff;
      // background-color: cadetblue;
    }
    .content {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 60px;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
    }
  }
  .button {
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
  }
}
