// @import '~@maxtropy/antd-theme/antd-dark.css';
html {
  background-color: #0e1820;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
}

body {
  margin: 0;
  background-color: #212d3f;
  font-family: 'Microsoft YaHei', 'PingFang SC', '黑体', '宋体', sans-serif;
}

#root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-text-fill-color: rgba(255, 255, 255, 0.75);
  caret-color: rgba(255, 255, 255, 0.75);
  box-shadow: inherit;
  transition: background-color 7200s ease-in-out 0s;
}
