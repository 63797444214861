.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding-top: 15px;
  .alertMessage {
    margin-top: 15px;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 70px;
  color: rgba(255, 255, 255, 0.85);
}

.dialog {
  background: #2f3d4f;
  width: 740px;
  margin-top: 150px;
  padding: 70px 180px 100px;
}
@media screen and (max-width: 1000px) {
  .dialog {
    width: 100%;
    padding: 70px 38px;
    margin-top: 0;
    height: 100vh;
  }
  .wrapper {
    .alertMessage {
      margin: 0;
      width: 100%;
    }
  }
}

.captcha {
  &:not(:empty) {
    margin-bottom: 24px;
  }
}
